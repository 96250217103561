<template>
  <div v-if="isshowgrid">
    <va-card title="Advertisement List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input
            class="va-input-search-container"
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex md6 text-right">
          <va-button color="success" v-if="role" icon="fa fa-plus" @click="addFunction()">Add</va-button>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
        <template slot="verify" slot-scope="props">
          <va-button v-if="props.rowData.status == 'approval_pending'" small color="#FFC107" @click="getADDdetails(props.rowData)" >Verify</va-button>
        </template>
        <template slot="view" slot-scope="props">
          <va-button small color="#FFC107" @click="getADDdetails(props.rowData)" >View</va-button>
          <va-button v-if="role" flat small color="red" icon="fa fa-remove" @click="remove(props.rowData)" class="ma-0"></va-button>
        </template>
        <template slot="status" slot-scope="props">
          <div v-if="props.rowData.status == 'COMPLETED'">Completed</div>
        </template>
      </va-data-table>
      <va-modal
        v-if="!mso_role"
        v-model="showSmallModal"
        size="small"
        title="Advertisement"
        okText="OK"
        cancelText="Cancel"
      >
        <div class="cursor" @click="cancel()" style="font-size: 22px;">x</div>
        <div class="scroll_overflow"><img :src="'data:image.png;base64,' + imgOne" alt="Logo" height="200"></div>
        <div class="scroll_overflow"><img :src="'data:image.png;base64,' + imgTwo" alt="Logo"></div>
      </va-modal>
      <va-modal
        v-if="mso_role"
        v-model="showSmallModal"
        size="small"
        title="Advertisement"
        okText="Approve"
        cancelText="Decline"
        @ok="Updatestauts(advertisement_id,'Approved')"
        @cancel="Updatestauts(advertisement_id,'Declined')"
      >
        <div class="cursor" @click="cancel()" style="font-size: 22px;">x</div>
        <div class="scroll_overflow"><img :src="'data:image.png;base64,' + imgOne" alt="Logo" height="200"></div>
        <div class="scroll_overflow"><img :src="'data:image.png;base64,' + imgTwo" alt="Logo"></div>
      </va-modal>
      <va-modal
          v-model="showRemoveModal"
          title="Delete Advertisement"
          size='small'
          :message="msg"
          okText="Confirm"
          cancelText="Cancel"
          @ok="deleteAdvertisement(entity)"
          @cancel="cancel()">
        </va-modal>
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card title="Create Advertisement">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <div class="upload_logo">
              <span class="va-form-label va-form-required-label">Left Image (720px X 2160px)</span>
              <va-input
                v-model="left_image_name"
                :disabled='true'
                placeholder="Upload Logo"
                :error="!!leftImageErrors.length"
                :error-messages="leftImageErrors">
                <section  slot="append" style="display: flex;" >
                  <va-button small @click.prevent="removeLogo()" class='entypo entypo-cancel' color='danger' />
                  <va-button small @click.prevent="selectLeft">UPLOAD</va-button>
                </section>
              </va-input>
              <input
                ref="fileLeft" type="file" id="fileLeft"
                @input="pickLeft" style="display: none;" accept="image/*">
            </div>
            <div class="upload_logo">
              <span class="va-form-required-label va-form-label">Bottom Image (3840px X 768px)</span>
              <va-input
                v-model="bottom_image_name"
                :disabled='true'
                placeholder="Upload Logo"
                :error="!!bottomImageErrors.length"
                :error-messages="bottomImageErrors">
                <section  slot="append" style="display: flex;" >
                  <va-button small @click.prevent="removeLogo()" class='entypo entypo-cancel' color='danger' />
                  <va-button small @click.prevent="selectBottom">UPLOAD</va-button>
                </section>
              </va-input>
              <input
                ref="fileBottom" type="file" id="fileBottom"
                @input="pickBottom" style="display: none;" accept="image/*">
            </div>
            <div>
              <span class="va-form-required-label va-form-label">Start Date</span>
              <datepicker
                class="date_picker"
                v-model="add.start_from_date"
                format="MMM dd yyyy"
                :disabledDates="disabledDate"/>
            </div>
            <div>
              <span class="va-form-required-label va-form-label">End Date</span>
              <datepicker
                class="date_picker"
                v-model="add.end_from_date"
                format= "MMM dd yyyy"
                :disabledDates="disabledDate"
              />
            </div>
            <span class="va-form-required-label va-form-label">Available Time</span>
            <multiselect
              placeholder="Select Available Time"
              v-model="time"
              label="slot_time"
              id="example"
              @input="get_ad_details(add.start_from_date,time)"
              multiple="true"
              :options="timeArr"
              :close-on-select="false"
              :clear-on-select="true"  
              :preserve-search="true"
              :hide-selected="false"
              :taggable="false"
              track-by="id"
            />
            <div
              v-if="timeErrors.length > 0"
              class="errors">
              {{timeErrors[0]}}
            </div>

            <div class="d-flex justify--end mt-3">
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createadd()">Create</va-button>
              <va-button type="submit" class="my-0" @click.prevent="list()">Cancel</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
import Datepicker from 'vuejs-datepicker'

Vue.use(vueResource)
export default {
  name: 'advertisement',
  components: { Datepicker },
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      showSmallModal: false,
      data: '',
      left_image: '',
      end_date: '',
      //toDisabledDate: { from: new Date(Date.now()) },
      role: '',
      mso_role: '',
      disabledDate: {
        to: null,
      },
      content: '',
      content1: '',
      add: {
        start_from_date: '',
        end_from_date: '',
        timing: '',
        amount: '',
        operator_margin: '',
        mso_margin: '',
        sys_margin: '',
        payable: '',
        amount_include_gst: '',
        operator_margin_include_gst: '',
        mso_margin_include_gst: '',
        sys_margin_include_gst: '',
        payable_include_gst: '',
      },
      imgOne: '',
      time: [],
      bottom_image: '',
      bottom_image_name: '',
      left_image_name: '',
      imgTwo: '',
      advertisement_id: '',
      leftImageErrors: [],
      bottomImageErrors: [],
      timeArr: [],
      add_list: [],
      timeErrors: [],
      showRemoveModal: false,
      msg: '',
      supportRole:false
    }
  },
  computed: {
    formReady () {
      return !this.timeErrors.length
    },
    fields () {
      this.add_list.map(function (item) {
        item.time = item.time.toString()
      })
      if (this.sys_role || this.supportRole) {
        return [{
          name: 'sno',
          title: 'S.NO',
          width: '6%',
        }, 
        {
          name: 'org_name',
          title: 'Organization Name',
        },
        {
          name: 'start_date',
          title: 'Start Date',
        },
        {
          name: 'end_date',
          title: 'End Date',
        },
        {
          name: 'time',
          title: 'Available Time',
        },
        {
          name: '__slot:status',
          title: 'Status',
        },
        {
          name: '__slot:view',
          dataClass: 'text-right',
        }]
      } else if (this.mso_role) {
        return [{
          name: 'sno',
          title: 'S.NO',
          width: '6%',
        }, 
        {
          name: 'org_name',
          title: 'Organization Name',
        },
        {
          name: 'start_date',
          title: 'Start Date',
        },
        {
          name: 'end_date',
          title: 'End Date',
        },
        {
          name: 'time',
          title: 'Available Time',
        },
        {
          name: '__slot:status',
          title: 'Status',
        },
        {
          name: '__slot:verify',
          dataClass: 'text-right',
        }]
      } else if (this.role) {
        return [{
          name: 'sno',
          title: 'S.NO',
          width: '6%',
        }, 
        {
          name: 'org_name',
          title: 'Organization Name',
        },
        {
          name: 'start_date',
          title: 'Start Date',
        },
        {
          name: 'end_date',
          title: 'End Date',
        },
        {
          name: 'time',
          title: 'Available Time',
        },
        {
          name: '__slot:status',
          title: 'Status',
        },
        {
          name: '__slot:view',
          dataClass: 'text-right',
        }]
      }
    },
    filteredData () {
      return search(this.term, this.add_list)
    },
  },
  created () {
    this.getAdvertisementList()
  },
  methods: {
    get_ad_details (start_date, data) {
      if (data.length != 0) {
        this.end_date = new Date(start_date.getFullYear() + '-' + ('0' + (start_date.getMonth() + 1)).slice(-2) + '-' + ('0' + (start_date.getDate())).slice(-2))
        var total_amount = 0
        var slot_id = data.map(function (index) {
          return index.ad_slot_id
        })
        if (start_date && this.end_date && slot_id.length > 0) {
          this.$http.get(config.menu.host + '/advertisement/ad_payment/' + start_date +
            '/' + this.end_date + '/' + slot_id).then(data => {
            var data = data.body
            this.add.total_amount = data.total_amount
            this.add.operator_margin = data.operator_margin
            this.add.mso_margin = data.mso_margin
            this.add.sys_margin = data.sys_margin
            this.add.payable = Number((data.mso_margin + data.sys_margin).toFixed(2))
            this.add.total_amount_include_gst = data.total_amount_include_gst
            this.add.operator_margin_include_gst = data.operator_margin_include_gst
            this.add.mso_margin_include_gst = data.mso_margin_include_gst
            this.add.sys_margin_include_gst = data.sys_margin_include_gst
            this.add.payable_include_gst = Number((data.mso_margin_include_gst + data.sys_margin_include_gst).toFixed(2))
            // $scope.operator_balance            = Number(data.operator_balance)
            // $scope.adjustable_amount   = $scope.operator_balance>0?$scope.operator_balance:0
            // if($scope.operator_balance && $scope.operator_balance>this.add.payable_include_gst){
            //     $scope.adjustable_amount=this.add.payable_include_gst
            // }
            // $scope.payable_after_adjustment=(this.add.payable_include_gst-$scope.adjustable_amount).toFixed(2)
          }, function (err) {

          })
        } else if (!start_date) {
          Vue.notify({ text: 'Please check the start and end date', type: 'error' })
        }
      }
    },
    removeLogo () {
      this.left_image_name = ''
      this.bottom_image_name = ''
    },
    selectLeft () {
      this.$refs.fileLeft.click()
    },
    selectBottom () {
      this.$refs.fileBottom.click()
    },
    pickLeft () {
      const input = this.$refs.fileLeft
      const file = input.files
      if (file && file[0]) {
        const imageReader = () => new Promise((resolve, _) => {
          const reader = new FileReader()
          const image = new Image()
          reader.onload = e => {
            image.src = reader.result
            image.onload = () => {
              resolve({
                result: e.target.result,
                width: image.width,
                height: image.height,
              })
              var ratio = image.width / image.height
              if (!(ratio >= 0.33 && ratio <= 0.55)) {
                return alert('Please check the height:width')
              }
            }
          }
          reader.readAsDataURL(file[0])
          this.content = file[0]
        })
        imageReader()
          .then(property => {
            if (property.width == 720 && property.height == 2160) {
              this.left_image = property.result
              this.left_image_name = file[0].name
              this.logo_data = file[0]
            } else {
              Vue.notify({
                text: 'logo height and width should be 720 X 2160',
                type: 'error',
              })
            }
          })
      }
    },
    pickBottom () {
      const input = this.$refs.fileBottom
      const file = input.files
      if (file && file[0]) {
        const imageReader = () => new Promise((resolve, _) => {
          const reader = new FileReader()
          const image = new Image()
          reader.onload = e => {
            image.src = reader.result
            image.onload = () => {
              resolve({
                result: e.target.result,
                width: image.width,
                height: image.height,
              })
              var ratio = image.width / image.height
              if (!(ratio >= 4.7 && ratio <= 5.3)) {
                return alert('Please check the height:width')
              }
            }
          }
          reader.readAsDataURL(file[0])
          this.content1 = file[0]
        })
        imageReader()
          .then(property => {
            if (property.width == 3840 && property.height == 768) {
              this.bottom_image = property.result
              this.bottom_image_name = file[0].name
              this.logo_data = file[0]
            } else {
              Vue.notify({
                text: 'logo height and width should be 3840 X 768',
                type: 'error',
              })
            }
          })
      }
    },
    Updatestauts (advertisement_id, status) {
      this.$http.put(config.menu.host + '/advertisement/' + advertisement_id + '/' + status).then(response => {
        this.showSmallModal = false
        Vue.notify({ text: response.body, type: 'success' })
        this.getAdvertisementList()
      }, function (err) {
        Vue.notify({ text: response.body, type: 'error' })
      })
    },
    cancel () {
      this.showSmallModal = false
    },
    getADDdetails (entity) {
      this.$http.get(config.menu.host + '/advertisement/ads/' + entity.advertisement_id).then(response => {
        this.data = response.body
        this.imgOne = response.body[0].data
        this.imgTwo = response.body[1].data
        this.advertisement_id = entity.advertisement_id
        this.showSmallModal = true
      })
    },
    getAdvertisementList () {
      const today = new Date()
      const yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      this.disabledDate.to = yesterday
      var userRole = Vue.$cookies.get('roles')
      if (userRole.includes('OPERATOR')) {
        this.role = true
      } else if (userRole.includes('RESELLER')) {
        this.mso_role = true
      } else if (userRole.includes('ADMIN')) {
        this.sys_role = true
      }else if(userRole.includes('SUPPORT')){
        this.supportRole=true
      }
      this.$http.get(config.menu.host + '/advertisement').then(response => {
        let index = 0
        this.add_list = response.body.map(function (item) {          
          //item.time = JSON.parse(item)
          item.id = index++
          item.sno = index
          item.is_existing = true
          item.checkbox_value = false
          return item
        })
        this.isshowForm = false
        this.isshowgrid = true
      })
    },
    createadd () {
      if ((this.content == '') || (this.content1 == '')) {
        return Vue.notify({ text: "Please Upload the images", type: 'error' })
      }
      if (this.time.length == 0) {
        return Vue.notify({ text: "Please select the timings", type: 'error' })
      }
      const header = {
        header: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const payload = new FormData()
      payload.append('start_from_date', this.add.start_from_date)
      payload.append('end_from_date', this.add.end_from_date)
      payload.append('timing', JSON.stringify(this.time))
      payload.append('content', this.content)
      payload.append('content1', this.content1)
      payload.append('amount', this.add.total_amount)
      payload.append('operator_margin', this.add.operator_margin)
      payload.append('mso_margin', this.add.mso_margin)
      payload.append('sys_margin', this.add.sys_margin)
      payload.append('payable', this.add.payable)
      payload.append('amount_include_gst', this.add.total_amount_include_gst)
      payload.append('operator_margin_include_gst', this.add.operator_margin_include_gst)
      payload.append('mso_margin_include_gst', this.add.mso_margin_include_gst)
      payload.append('sys_margin_include_gst', this.add.sys_margin_include_gst)
      payload.append('payable_include_gst', this.add.payable_include_gst)

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/advertisement', payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
          this.list()
        }
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    checktiming (date) {
      this.$http.get(config.menu.host + '/advertisement/timings/' + date).then(response => {
        response.body.map(function (item, i) {
          item.id = i + 1
        })
        this.timeArr = response.body
      })
    },
    addFunction () {
      var date = new Date(new Date().getTime())
      var default_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + (date.getDate())).slice(-2)
      this.add.start_from_date = new Date(default_date)
      this.add.end_from_date = new Date(default_date)
      // this.end_date = new Date(default_date)

      var current_date = new Date(new Date().getTime())
      var check_date = current_date.getFullYear() + '-' + ('0' + (current_date.getMonth() + 1)).slice(-2) + '-' + ('0' + (current_date.getDate())).slice(-2)
      this.checktiming(new Date(check_date))

      this.time = []
      this.add.start_from_date = this.add.start_from_date
      this.left_image = ''
      this.left_image_name = ''
      this.bottom_image = ''
      this.bottom_image_name = ''
      this.timeErrors = []
      this.err = []
      this.isshowForm = true
      this.isshowgrid = false
      this.isCreate = true
    },
    remove (row) {
      this.msg = 'Are you sure to delete the Advertisement ' + row.org_name + ' ?'
      this.entity = row
      this.showRemoveModal = true
    },
    cancel () {
      this.showRemoveModal = false
    },
    deleteAdvertisement (data) {
      this.$http.delete(config.menu.host + '/advertisement/' + data.advertisement_id).then(resp => {
        Vue.notify({ text: resp.body, type: 'success' })
        this.list()
        this.getAdvertisementList()
      }, err => {
        if (err && err.body) {
          Vue.notify({ text: err.body, type: 'error' })
        }
      })
    },
    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.getAdvertisementList()
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
}
</script>
<style type="text/css">
  .scroll_overflow {
    overflow: auto;
  }

  .errors {
    color: #ff0000;
    font-size: 12px;
  }

  .cursor {
    cursor: pointer;
    float: right;
    margin-top: -45px;
  }

  .date_picker_label {
    color: #2c82e0;
    font-size: 0.7rem;
    margin-left: 10px;
    font-weight: bold;
  }
</style>
